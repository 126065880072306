import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import AOS from "aos";
import "aos/dist/aos.css";
import OpenRoutes from "./OpenRoutes";
import AnalyticsTracker from "../../components/AnalyticsTracker";
import "../../utils/fireBase";
import { ARMY_LINK } from "../../utils/constant";
import "./App.scss";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const location = useLocation();
  return (
    <div className={`App ${location?.pathname === ARMY_LINK ? " " : ""}`}>
      <HelmetProvider>
        <AnalyticsTracker />
        <OpenRoutes />
      </HelmetProvider>
      <ToastContainer />
    </div>
  );
}

export default App;
